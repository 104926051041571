import React from "react";
import Instagram from "../images/Instagram.svg";
import Twitter from "../images/twitter.svg";
import Mail from "../images/mail.svg";
import BorderLine from "../images/BG Gradient.jpg";
import { faApple, faGooglePay, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import StoreButton from "../subcomponents/StoreButton";
import { ANDROID_STORE_URL, APPLE_STORE_URL } from "../constants";

const Footer = () => {
  return (
    <>
      <div className="h-100 bg-cover">
        <img src={BorderLine} alt="img" className="h-px w-100" width={"100%"} />
      </div>
      <div className="max-w-7xl mx-auto py-12 px-3">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <div>
            <h4 className="text-white font-semibold font-Inter mb-7">
              What are you waiting for?
            </h4>
            <div className="flex gap-3 mb-7">

              <div className="flex flex-col items-center space-y-2 mt-4 lg:flex-row lg:space-y-0 lg:space-x-2">
                <StoreButton text="Download iOS" icon={faApple} variant="secondary" onClick={() => window.open(APPLE_STORE_URL, "_blank", "noreferrer")} />
                <StoreButton text="Download Android" icon={faGooglePlay} variant="secondary" onClick={() => window.open(ANDROID_STORE_URL, "_blank", "noreferrer")} />
              </div>
            </div>

            <div className="flex gap-7 items-center">
              <a href="">
                <img src={Instagram} />
              </a>
              <a href="">
                <img src={Twitter} />
              </a>
              <a href="">
                <img src={Mail} />
              </a>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2">
            <ul className="mt-4">
              <li className="mt-2">
                <a
                  href="https://api.justpush.io/terms-and-conditions"
                  className="text-[18px] text-[#d3d3d3] font-Inter font-normal"
                >
                  Terms & Condtions
                </a>
              </li>
              <li className="mt-2">
                <a
                  href="https://api.justpush.io/privacy-policy"
                  className="text-[18px] text-[#d3d3d3] font-Inter font-normal"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="mt-2">
                <a
                  href="mailto:info@justpush.io?subject=Request account deletion"
                  className="text-[18px] text-[#d3d3d3] font-Inter font-normal"
                >
                  Request account deletion
                </a>
              </li>
              <li className="mt-2">
                <a
                  href="/support"
                  className="text-[18px] text-[#d3d3d3] font-Inter font-normal"
                >
                  Support
                </a>
              </li>
            </ul>
            <ul className="mt-4">
              <li>
                <strong className="font-Inter text-base text-white font-semibold">
                  Social media
                </strong>
              </li>
              <li className="mt-2">
                <a
                  href=""
                  className="text-[18px] text-[#d3d3d3] font-Inter font-normal"
                >
                  Instagram
                </a>
              </li>
              <li className="mt-2">
                <a
                  href=""
                  className="text-[18px] text-[#d3d3d3] font-Inter font-normal"
                >
                  Facebook
                </a>
              </li>
              <li className="mt-2">
                <a
                  href=""
                  className="text-[18px] text-[#d3d3d3] font-Inter font-normal"
                >
                  Linkedin
                </a>
              </li>
              <li className="mt-2">
                <a
                  href=""
                  className="text-[18px] text-[#d3d3d3] font-Inter font-normal"
                >
                  Twitter
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
